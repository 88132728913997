import { useEffect, useState } from "react";
import InputXML from "../components/InputXML";
import x2js from 'x2js'
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tabela from "../components/TabelaNFe";

import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Alert from '@mui/material/Alert';

function ImportarXMLNFe({ location }) {
  location = useLocation();
  const [files, setFiles] = useState();
  const [allData, setAllData] = useState();
  const [cont, setCont] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const linhas = [];
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [dadosFiltrados, setDadosFiltrados] = useState();
  const [pdfNotas, setPdfNotas] = useState();
  const [retAtivo, setRetAtivo] = useState(false);
  const [autopecasAtivo, setAutopecasAtivo] = useState(false);
  const [construcaoCivilAtivo, setConstrucaoCivilAtivo] = useState(false);

  function createData(Chave, Nota_Fiscal, NCM, Data, Empresa, Produto, CST, CFOP, IPI, Valor_Produto, Desconto, Outras_Despesas, Frete, ICMS, ICMSRET, ICMSAUTOPECAS, ICMSCONSTRUCAOCIVIL) {
    return { Chave, Nota_Fiscal, NCM, Data, Empresa, Produto, CST, CFOP, IPI, Valor_Produto, Desconto, Outras_Despesas, Frete, ICMS, ICMSRET, ICMSAUTOPECAS, ICMSCONSTRUCAOCIVIL };
  }
  
  const getPorcentagem = (ano) => {
    var porcentagem = [];
    porcentagem["00"] = 4;
    porcentagem["20"] = 1.54;
    porcentagem["Outros"] = 7;
    switch(ano){
      case 2023:
        porcentagem["00"] = 3.6;
        porcentagem["20"] = 1.4;
        porcentagem["Outros"] = 6.6;
        break;
      case 2024:
        porcentagem["00"] = 4;
        porcentagem["20"] = 1.54;
        porcentagem["Outros"] = 7;
        break;
    }
    return porcentagem;
  }
  
  const getPorcentagemRet = (ano) => {
    var porcentagem = [];
    switch(ano){
      case 2023:
        porcentagem["00"] = 3.36;
        porcentagem["20"] = 2;
        porcentagem["Outros"] = 6.36;
        break;
      case 2024:
        porcentagem["00"] = 4.08;
        porcentagem["20"] = 2.19;
        porcentagem["Outros"] = 7.08;
        break;
      default:
        porcentagem["00"] = 3.36;
        porcentagem["20"] = 2;
        porcentagem["Outros"] = 6.36;
        break;
    }
    return porcentagem;
  }
  
  const getPorcentagemAutopecas = (ano) => {
    var porcentagem = [];
    switch(ano){
      default:
        porcentagem["00"] = 8;
        porcentagem["20"] = 8;
        porcentagem["Outros"] = 5;
        break;
    }
    return porcentagem;
  }
  
  const getPorcentagemConstrucaoCivil = (ano) => {
    var porcentagem = [];
    switch(ano){
      default:
        porcentagem["00"] = 4.53;
        porcentagem["20"] = 4.53;
        porcentagem["Outros"] = 4.53;
        break;
    }
    return porcentagem;
  }

  const dadosNotas = (dados, pedeefes) => {
    for (var nota in dados) {
      if (dados.hasOwnProperty(nota)) {
        var valorIPI;
        var valorCST = "00";
        var valorDesc, valorOutro, valorFrete, valorAliquota, valorAliquotaRet, valorAliquotaAutopecas, valorAliquotaConstrucaoCivil;
        const qtdItem = (dados[nota].det.length ? dados[nota].det.length : 1);
        const data = new Date(dados[nota].ide.dhEmi);
        const porcentagemRet = getPorcentagemRet(data.getFullYear())
        const porcentagemAutopecas = getPorcentagemAutopecas(data.getFullYear())
        const porcentagemConstrucaoCivil = getPorcentagemConstrucaoCivil(data.getFullYear())
        const porcentagem = getPorcentagem(data.getFullYear())
        const dataCompleta = (parseInt(data.getDate()) < 10 ? "0" : "") + data.getDate() + "/" + (parseInt(data.getMonth()) < 9 ? "0" : "") +(parseInt(data.getMonth()) + 1) + "/" + data.getFullYear()
        if (qtdItem > 1) { 
            for (var ind = 0; ind < qtdItem; ind++) {
              if (dados[nota].det[ind].imposto !== undefined) {
                if (dados[nota].det[ind].imposto.IPI !== undefined) {
                  if (dados[nota].det[ind].imposto.IPI.IPITrib !== undefined) {
                    valorIPI = dados[nota].det[ind].imposto.IPI.IPITrib.vIPI
                  } else {
                    valorIPI = "0.00"
                  }
                } else {
                  valorIPI = "0.00" 
                }
              } else {
                valorIPI = "0.00"
              }
              if ((Object.values(dados[nota].det[ind].imposto.ICMS)[0].CST === "20" ||
              Object.values(dados[nota].det[ind].imposto.ICMS)[0].CST === "00" ||
              Object.values(dados[nota].det[ind].imposto.ICMS)[0].CSOSN === "101" ||
              Object.values(dados[nota].det[ind].imposto.ICMS)[0].CSOSN === "102" ||
              Object.values(dados[nota].det[ind].imposto.ICMS)[0].CSOSN === "400" ||
                Object.values(dados[nota].det[ind].imposto.ICMS)[0].CSOSN === "900") && (
                  dados[nota].det[ind].prod.CFOP > 4999 && dados[nota].det[ind].prod.CFOP < 6000
              )) {
                valorCST = Object.values(dados[nota].det[ind].imposto.ICMS)[0].CST ?
                  Object.values(dados[nota].det[ind].imposto.ICMS)[0].CST :
                  Object.values(dados[nota].det[ind].imposto.ICMS)[0].CSOSN;
                valorDesc = dados[nota].det[ind].prod.vDesc ? (parseFloat(dados[nota].det[ind].prod.vDesc)).toFixed(2) : (0.00).toFixed(2)
                valorOutro = (parseFloat(dados[nota].total.ICMSTot.vOutro) / qtdItem).toFixed(2)
                valorFrete = (parseFloat(dados[nota].total.ICMSTot.vFrete) / qtdItem).toFixed(2)
                var valorTotal = parseFloat(valorIPI) + parseFloat(valorOutro) + parseFloat(valorFrete) - parseFloat(valorDesc) + parseFloat(dados[nota].det[ind].prod.vProd);
                var valorDividendo = 1;
                var valorDividendoRet = 1;
                var valorDividendoAutopecas = 1;
                var valorDividendoConstrucaoCivil = 1;
                if (valorCST === "00") {
                  valorDividendo = porcentagem["00"]/100
                  valorDividendoRet = porcentagemRet["00"]/100
                  valorDividendoAutopecas = porcentagemAutopecas["00"]/100
                  valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["00"]/100
                } else {
                  if (valorCST === "20") {
                    valorDividendo = porcentagem["20"]/100
                    valorDividendoRet = porcentagemRet["20"]/100
                    valorDividendoAutopecas = porcentagemAutopecas["20"]/100
                    valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["20"]/100
                  } else {
                    valorDividendo = porcentagem["Outros"]/100
                    valorDividendoRet = porcentagemRet["Outros"]/100
                    valorDividendoAutopecas = porcentagemAutopecas["Outros"]/100
                    valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["Outros"]/100
                  }
                }
                valorAliquota = (valorTotal * valorDividendo).toFixed(2)
                valorAliquotaRet = (valorTotal * valorDividendoRet).toFixed(2)
                valorAliquotaAutopecas = ((valorTotal * 0.35) * valorDividendoAutopecas).toFixed(2)
                valorAliquotaConstrucaoCivil = (valorTotal * valorDividendoConstrucaoCivil).toFixed(2)
                linhas.push(createData(nota,
                  dados[nota].ide.nNF,
                  dados[nota].det[ind].prod.NCM,
                  dataCompleta,
                  dados[nota].emit.xNome,
                  dados[nota].det[ind].prod.xProd,
                  valorCST,
                  dados[nota].det[ind].prod.CFOP,
                  valorIPI,
                  dados[nota].det[ind].prod.vProd,
                  valorDesc,
                  valorOutro,
                  valorFrete,
                valorAliquota,
                valorAliquotaRet,
                valorAliquotaAutopecas,
                valorAliquotaConstrucaoCivil))
              } 
              }
          } else {
            if (dados[nota].det.imposto !== undefined) {
              if (dados[nota].det.imposto.IPI !== undefined) {
                if (dados[nota].det.imposto.IPI.IPITrib !== undefined) {
                  valorIPI = dados[nota].det.imposto.IPI.IPITrib.vIPI
                } else {
                  valorIPI = "0.00"
                }
              } else {
                valorIPI = "0.00"
              }
            } else {
              valorIPI = "0.00"
            }
            if ((Object.values(dados[nota].det.imposto.ICMS)[0].CST === "20" ||
            Object.values(dados[nota].det.imposto.ICMS)[0].CST === "00" ||
            Object.values(dados[nota].det.imposto.ICMS)[0].CSOSN === "101" ||
            Object.values(dados[nota].det.imposto.ICMS)[0].CSOSN === "102" ||
            Object.values(dados[nota].det.imposto.ICMS)[0].CSOSN === "400" ||
              Object.values(dados[nota].det.imposto.ICMS)[0].CSOSN === "900") && (
                dados[nota].det.prod.CFOP > 4999 && dados[nota].det.prod.CFOP < 6000
            )) {
              valorCST = Object.values(dados[nota].det.imposto.ICMS)[0].CST ?
                Object.values(dados[nota].det.imposto.ICMS)[0].CST :
                Object.values(dados[nota].det.imposto.ICMS)[0].CSOSN;
              valorDesc = dados[nota].det.prod.vDesc ? (parseFloat(dados[nota].det.prod.vDesc)).toFixed(2) : (0.00).toFixed(2)
              valorOutro = (parseFloat(dados[nota].total.ICMSTot.vOutro) / qtdItem).toFixed(2)
              valorFrete = (parseFloat(dados[nota].total.ICMSTot.vFrete) / qtdItem).toFixed(2)
              var valorTotal = parseFloat(valorIPI) + parseFloat(valorOutro) + parseFloat(valorFrete) - parseFloat(valorDesc) + parseFloat(dados[nota].det.prod.vProd);
              console.log(dados[nota])
              console.log(parseFloat(valorIPI) + " " + parseFloat(valorOutro))
              var valorDividendo = 1;
              var valorDividendoRet = 1;
              var valorDividendoAutopecas = 1;
              var valorDividendoConstrucaoCivil = 1;
              if (valorCST === "00") {
                valorDividendo = porcentagem["00"]/100
                valorDividendoRet = porcentagemRet["00"]/100
                valorDividendoAutopecas = porcentagemAutopecas["00"]/100
                valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["00"]/100
              } else {
                if (valorCST === "20") {
                  valorDividendo = porcentagem["20"]/100
                  valorDividendoRet = porcentagemRet["20"]/100
                  valorDividendoAutopecas = porcentagemAutopecas["20"]/100
                  valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["20"]/100
                } else {
                  valorDividendo = porcentagem["Outros"]/100
                  valorDividendoRet = porcentagemRet["Outros"]/100
                  valorDividendoAutopecas = porcentagemAutopecas["Outros"]/100
                  valorDividendoConstrucaoCivil = porcentagemConstrucaoCivil["Outros"]/100
                }
              }
              valorAliquota = (valorTotal * valorDividendo).toFixed(2)
              valorAliquotaRet = (valorTotal * valorDividendoRet).toFixed(2)
              valorAliquotaAutopecas = (valorTotal * valorDividendoAutopecas).toFixed(2)
              valorAliquotaConstrucaoCivil = ((valorTotal * 0.35) * valorDividendoConstrucaoCivil).toFixed(2)
              linhas.push(createData(nota,
                dados[nota].ide.nNF,
                dados[nota].det.prod.NCM,
                dataCompleta,
                dados[nota].emit.xNome,
                dados[nota].det.prod.xProd,
                valorCST,
                dados[nota].det.prod.CFOP,
                valorIPI,
                dados[nota].det.prod.vProd,
                valorDesc,
                valorOutro,
                valorFrete,
                valorAliquota,
                valorAliquotaRet,
                valorAliquotaAutopecas,
                valorAliquotaConstrucaoCivil
              ))
            }
          }
        setAllData([...linhas]);
        setDadosFiltrados([...linhas])
        setPdfNotas(pedeefes)
      }
      setNomeEmpresa(dados[nota].dest.xNome)
  }
  }

  const getActiveContente = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className="centralizar">
            <div className="UploadBox" >
            <InputXML title="NFe"
            setFiles={setFiles}
            />
            <div className="LadoDireito">
              {files !== undefined ?
                <div className="InfoProgress">
                  <div style={{marginBottom: '20px', marginRight: '5px'}}>
                    {cont === files.length ? <CheckCircleIcon sx={{ color: '#5049CC' }} size={90} /> : <CircularProgress sx={{ color: '#5049CC' }} size={28} />}
                    </div>
                  <div>
                    <div className="ArquivosProntos">
                    {cont + "/" + files.length + ' arquivos prontos'}
                    </div> 
                    <BorderLinearProgress variant="determinate" value={cont * 100 / files.length} />
                </div>
                </div> : ''}
              {files !== undefined && cont === files.length && allData.length === 0 ?
              <Alert severity="warning">Arquivo selecionado não possui  nenhuma<br/> NFe com CST 00, 20, 101, <br/>102, 400 ou 900. <br/>Por favor, selecione uma outra nota.</Alert>
              : ""}

              {files === undefined || cont !== files.length || allData.length === 0 ? "" : 
                <div className="BotaoContinuar">
                  <button id="continueButton"
                onClick={()=>{setActiveStep(activeStep+1)}}>
                <h3>Continuar</h3> <ArrowForwardIcon size={18}/>
              </button>
              </div>
              }
            </div>
          </div>
          </div>
        );
        case 1:
          return (
            <Tabela nomeEmpresa={nomeEmpresa}
            setRetAtivo={setRetAtivo}
            retAtivo={retAtivo}
            setAutopecasAtivo={setAutopecasAtivo}
            autopecasAtivo={autopecasAtivo}
            setConstrucaoCivilAtivo={setConstrucaoCivilAtivo}
            construcaoCivilAtivo={construcaoCivilAtivo}
            pdfNotas={pdfNotas}
              allData={allData}
              dadosFiltrados={dadosFiltrados}
              setDadosFiltrados={setDadosFiltrados}
            />
          )
        case 2:
          return (
            <div>Testando</div>
          )
      default:
        return (
          <div>nada pra ver</div>
        )
    }
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 7,
    borderRadius: 5,
    width: 250,
    marginBottom: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#5049CC' : '#5049CC',
    },
  }));

  useEffect(() => {
    if (files !== undefined) {
      var convert = new x2js();
      var chave, jsonDoc, prov, provPdf, todosDados, todosPdf;
      readmultifiles(files); 
      function readmultifiles(files) {
        setCont(0);
        var reader = new FileReader(); 
        function readFile(index) {
          if( index >= files.length ) return;
          var file = files[index];
          reader.onload = function(e) {  
            jsonDoc = convert.xml2js(reader.result);
            setCont(index + 1)
            if(jsonDoc.hasOwnProperty("nfeProc") && jsonDoc.nfeProc.hasOwnProperty("NFe") ){
              chave = jsonDoc.nfeProc.NFe.infNFe._Id.replace("NFe", "");
              prov = { [chave]: { ...jsonDoc.nfeProc.NFe.infNFe } }
              provPdf = { [chave] : reader.result}
              if (files.length === 1) {
                todosDados = prov;
                todosPdf = provPdf;
              } else {
                todosPdf = {...todosPdf, ...provPdf}
                todosDados = {...todosDados, ...prov}
              }
              if (index === files.length - 1) {
                dadosNotas(todosDados, todosPdf);
              }
            }
            readFile(index + 1)
          }
          reader.readAsText(file);
        }
        readFile(0);
      }
    }
  }, [files])


  return (
    <div className="App">
      {getActiveContente()}
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {activeStep === 0 ? '' :
            <div className="BotaoVoltar">
              <button id="backButton"
                onClick={() => { setActiveStep(activeStep - 1) }}>
                <h4>Voltar</h4>
              </button>
            </div>}
        </div>
    </div>
  );
}

export default ImportarXMLNFe;
